import React, { useEffect, useState, useContext } from "react";

import Platform from "system/Platform";
import FileItemComponent from "./FileItemComponent";

import ProjectContext from "system/ProjectContext";

export default function FileTreeComponent(props) {
    const { project, files } = useContext(ProjectContext);
    const [treeData, setTreeData] = useState(false);


    useEffect(() => {
        console.log('FileTree effect');
        //console.log('setTreeData', files);
        setTreeData(Platform.toTree([...files], props.project_id));
        //console.log('treeData', treeData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const ItemComponent = (props) => {
        return (
            <FileItemComponent {...props}>
                {props.children &&
                    props.children
                        .sort((a, b) => a.sort_order - b.sort_order)
                        .map((child) => {
                            return <ItemComponent file={child} children={child.children} project_id={props.project_id} key={child.id} />;
                        })}
            </FileItemComponent>
        );
    };
    return (
        <div id="file-tree">
            <ItemComponent file={project} className="project-item" children={treeData} project_id={props.project_id} />
        </div>
    );
}
