import React, { useEffect, useState, useContext } from "react";

import Loading from "components/Loading";
import Icon from "components/Icon";

import { utcFormat } from "functions";
import Platform from "system/Platform";

import ProjectContext from "system/ProjectContext";

export default function TrashComponent(props) {
    const [trash, setTrash] = useState(false);
    const { appendNewFile } = useContext(ProjectContext);

    useEffect(() => {
        //console.log("useEffect", props);
        Platform.system.listFiles(props.project_id, { trashed: "y" }).then((response) => {
            setTrash(response.data.files);
        });
    }, [props]);

    const undelete = (e, file) => {
        e.preventDefault();
        Platform.system.undeleteFile(file.id).then((response) => {
            appendNewFile(response.data);
        });
        return false;
    };

    if (!trash) {
        return <Loading>Dumpster Diving</Loading>;
    } else if (trash.length > 0) {
        return (
            <div className="table-responsive">
                <table className="table table-striped table-sm TrashItemsGrid">
                    <thead>
                        <tr>
                            <th width={120}></th>
                            <th>Name</th>
                            <th width={120}>Type</th>
                            <th width={220}>Created</th>
                            <th width={220}>Modified</th>
                            <th width={220}>Deleted</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trash.map((file) => {
                            return (
                                <tr key={file.id}>
                                    <td className="text-end">
                                        <a href="#root" onClick={(e) => undelete(e, file)} className="">
                                            <Icon icon="BiUndo" /> Restore
                                        </a>
                                    </td>
                                    <td>
                                        <Icon icon={file.icon} /> {file.name}
                                    </td>
                                    <td>
                                        <Icon icon={file.type} /> {file.type}
                                    </td>
                                    <td>{utcFormat(file.created_at)}</td>
                                    <td>{utcFormat(file.updated_at)}</td>
                                    <td>{utcFormat(file.deleted_at)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return <div className="m-5 text-center">Trash is empty.</div>;
    }
}
