import React, { useContext, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

import Platform from "system/Platform";

import ProjectContext from "system/ProjectContext";

import Icon from "components/Icon";

export default function ContextMenuComponent(props) {
    const ref = useRef(null);
    const { files, setFile, deleteFile, addFile, addFolder, duplicateFile, templates, updateViewConfig } = useContext(ProjectContext);
    const { event } = props;

    const data = event.srcElement.dataset;
    const type = event.srcElement.dataset.type;

    const file = Platform.fileById(files, data.id);

    //console.log(file, event.srcElement.dataset);
    useEffect(() => {
        ref.current.style.left = event.clientX + 50 + "px";
        ref.current.style.top = event.clientY + "px";
        let i = 0;
        while (!fitInScreen() && i < 10) {
            fitInScreen();
            i++;
        }
    }, [event]);

    const fitInScreen = () => {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= 0) {
            ref.current.style.top = "20px";
            return false;
        }
        if (rect.left <= 0) {
            ref.current.style.left = "20px";
            return false;
        }
        if (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight)) {
            ref.current.style.top = rect.y - 50 + "px";
            return false;
        }
        if (rect.right >= (window.innerWidth || document.documentElement.clientWidth)) {
            ref.current.style.left = "20px";
            return false;
        }
        return true;
    };

    const handleChangeName = (event) => {
        event.preventDefault();
        const name = prompt("Name", file.name);
        if (name) {
            file.name = name;
            setFile(file);
            Platform.system.updateFileMeta(file.id, { name: name });
        }
    };

    const handleAddFile = (event) => {
        event.preventDefault();
        const name = prompt("File Name");
        if (name) {
            addFile(name, data.id);
        }
    };

    const handleAddFolder = (event) => {
        event.preventDefault();
        const name = prompt("File Name");
        if (name) {
            addFolder(name, data.id);
        }
    };

    const handleDelete = (event) => {
        event.preventDefault();
        if (window.confirm("Are you sure?")) {
            deleteFile(file.id);
        }
    };

    const handleDuplicate = (event) => {
        event.preventDefault();
        const name = prompt("Name", file.name + " Copy");
        if (name) {
            duplicateFile(file, name);
        }
    };

    const handleAddTemplate = (event, template) => {
        event.preventDefault();
        const name = prompt("Name", "");
        if (name) {
            duplicateFile(template, name, file.id);
        }
    };

    let options = [];

    if (type === "file") {
        options.push(
            <NavLink to={file.link} key="edit">
                <Icon icon="BiEdit" /> Edit
            </NavLink>
        );
        options.push(
            <a href="#root" role="button" onClick={() => updateViewConfig("right_pane_document_id", file.id)} key="viewInRight">
                <Icon icon="bx-dock-right" /> View in Split
            </a>
        );
    } else {
        options.push(
            <NavLink to={data.link} key="open">
                <Icon icon="FaFolderOpen" /> Open
            </NavLink>
        );
    }

    if (file) {
        options.push(
            <a href="#root" role="button" onClick={handleChangeName} key="changeName">
                <Icon icon="BiEditAlt" /> Change Name
            </a>
        );
    }

    if (type === "folder" || type === "project") {
        options.push(
            <a href="#root" role="button" onClick={handleAddFile} key="addFile">
                <Icon icon="FaFileMedical" /> Add File
            </a>
        );
        options.push(
            <a href="#root" role="button" onClick={handleAddFolder} key="addFolder">
                <Icon icon="FaFolderPlus" /> Add Folder
            </a>
        );

        templates().forEach((template) => {
            options.push(
                <a href="#root" role="button" onClick={(event) => handleAddTemplate(event, template)} key={template.id}>
                    <Icon icon={template.icon} color={template.icon_color} /> Add {template.name}
                </a>
            );
        });
    }
    if (type !== "project") {
        /*
        options.push(
            <NavLink to={file.link + "/move"} key="move">
                <Icon icon="BiLeftTopArrowCircle" /> Move
            </NavLink>
        );
        */
        options.push(
            <a href="#root" role="button" onClick={handleDuplicate} key="duplicate">
                <Icon icon="FaCopy" /> Duplicate
            </a>
        );
        options.push(
            <a href="#root" role="button" onClick={handleDelete} key="delete">
                <Icon icon="FaTrashAlt" /> Delete
            </a>
        );
    }

    return (
        <div id="ContextMenu" ref={ref}>
            {options}
        </div>
    );
}
