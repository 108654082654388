
const themes = {
    Default: {href: "https://cdn.jsdelivr.net/npm/bootswatch@4.5.2/dist/lux/bootstrap.min.css", name: "Lux (default)"},
    Flatly: {href: "https://cdn.jsdelivr.net/npm/bootswatch@4.5.2/dist/flatly/bootstrap.min.css", name: "Flatly"},
    Darkly: {href: "https://cdn.jsdelivr.net/npm/bootswatch@4.5.2/dist/darkly/bootstrap.min.css", name: "Darkly"},
    Litera: {href: "https://cdn.jsdelivr.net/npm/bootswatch@4.5.2/dist/litera/bootstrap.min.css", name: "Litera"},
    Cosmo: {href: "https://cdn.jsdelivr.net/npm/bootswatch@4.5.2/dist/cosmo/bootstrap.min.css", name: "Cosmo"},
    Cerulean: {href: "https://cdn.jsdelivr.net/npm/bootswatch@4.5.2/dist/cerulean/bootstrap.min.css", name: "Cerulean"},
    Journal: {href: "https://cdn.jsdelivr.net/npm/bootswatch@4.5.2/dist/journal/bootstrap.min.css", name: "Journal"},
    Generic: {href: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css", name: "Generic"},
};

export function getTheme(name){
    if(themes.hasOwnProperty(name)){
        return themes[name].href;
    }
    return themes.Default.href;
}

export default themes;

