export default function CardComponent(props) {
    const { className = "", header = false, children, otherProps } = props;

    return (
        <div className={"card " + className} {...otherProps}>
            {header && <div className="card-header">{header}</div>}

            <div className="card-body">{children}</div>
        </div>
    );
}
