import Icon from "components/Icon";

import { Button, Dropdown } from "react-bootstrap";

export default function Toolbar(props) {
    const { editor } = props;
    if (!editor) {
        return null;
    }

    const handleLeafClick = (e, Element) => {
        e.preventDefault();
        console.log(editor.chain().focus());
        editor.chain().focus()[Element.action]().run();
    };

    const LeafStyles = () => {
        const buttons = [];
        for (let name in leaf_styles) {
            let Element = leaf_styles[name];
            let variant = editor.isActive(name) ? "primary" : "default";
            buttons.push(
                <Button onMouseDown={(e) => handleLeafClick(e, Element)} title={Element.title} key={name} variant={variant}>
                    <Icon icon={Element.icon} />
                </Button>
            );
        }
        return <>{buttons}</>;
    };

    const handleBlockClick = (e, Element) => {
        e.preventDefault();
        const { argument, action, wrap } = Element;
        if (action) {
            editor.chain().focus()[action](argument).run();
        } else if (wrap) {
            editor.chain().focus().setNode(wrap, argument).run();
        } else {
            console.error("unhandled", Element);
        }
    };
    const BlockDrowdown = () => {
        const options = [];
        let label = "Block Style";
        for (const name in block_elements) {
            let Element = block_elements[name];
            if (editor.isActive(Element.wrap, Element.argument)) {
                label = Element.title;
            }
            options.push(
                <Dropdown.Item onMouseDown={(e) => handleBlockClick(e, Element)} key={name}>
                    <Icon icon={Element.icon} /> {Element.title}
                </Dropdown.Item>
            );
        }
        return (
            <div className="dropdown">
                <Button variant="default" id="dropdown-basic" className="dropdown-toggle" type="button" data-toggle="dropdown">
                    {label}
                </Button>

                <div className="dropdown-menu">{options}</div>
            </div>
        );
    };

    const handleTableActionClick = (e, action) => {
        e.preventDefault();
        editor.chain().focus()[action]().run();
    };

    const TableOptions = () => {
        const options = [];

        for (let action in table_options) {
            let option = table_options[action];
            if (editor.can()[action]()) {
                options.push(
                    <Button onMouseDown={(e) => handleTableActionClick(e, action)} title={option.title} variant="default" key={action}>
                        <Icon icon={option.icon} />
                    </Button>
                );
            }
        }
        return <>{options}</>;
    };

    const handleAlignment = (e, action) => {
        e.preventDefault();
        editor.chain().focus().setTextAlign(action).run();
    };
    const AlignmentOptions = () => {
        const options = [];

        for (let action in alignment_options) {
            let option = alignment_options[action];
            let variant = editor.isActive({ textAlign: action }) ? "primary" : "default";
            options.push(
                <Button onMouseDown={(e) => handleAlignment(e, action)} title={option.title} variant={variant} key={action}>
                    <Icon icon={option.icon} />
                </Button>
            );
        }
        return <>{options}</>;
    };

    const handleInsert = (e, action, argument) => {
        e.preventDefault();
        editor.chain().focus()[action](argument).run();
    };
    const InsertOptions = () => {
        const options = [];
        for (let key in insert_options) {
            let option = insert_options[key];
            const { action, argument } = option;
            options.push(
                <Button onMouseDown={(e) => handleInsert(e, action, argument)} title={option.title} variant="default" key={key}>
                    <Icon icon={option.icon} />
                </Button>
            );
        }
        return <>{options}</>;
    };

    const handleListClick = (e, type) => {
        e.preventDefault();
        if (type === "ul") {
            editor.chain().focus().toggleBulletList().run();
        } else {
            editor.chain().focus().toggleOrderedList().run();
        }
    };

    const handleInsertTableClick = (e) => {
        e.preventDefault();
        editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true, class: "table table-striped" }).run();
    };

    return (
        <div className="tip-tap-toolbar btn-toolbar" id="toolbar" role="toobar">
            <div className="btn-group">
                <BlockDrowdown />
            </div>
            <div className="btn-group">
                <LeafStyles />
            </div>
            <div className="btn-group">
                <AlignmentOptions />
            </div>
            <div className="btn-group">
                <Button onMouseDown={(e) => handleListClick(e, "ol")} title="Ordered List" variant={editor.isActive("orderedList") ? "primary" : "default"}>
                    <Icon icon="BiListOl" />
                </Button>
                <Button onMouseDown={(e) => handleListClick(e, "ul")} title="Unordered List" variant={editor.isActive("bulletList") ? "primary" : "default"}>
                    <Icon icon="BiListUl" />
                </Button>
            </div>
            <div className="btn-group">
                <Button onMouseDown={(e) => handleInsertTableClick(e)} title="Insert Table" variant="default">
                    <Icon icon="BiTable" />
                </Button>
                <TableOptions />
            </div>
            <div className="btn-group">
                <InsertOptions />
            </div>
        </div>
    );
}

const leaf_styles = {
    bold: {
        title: "Bold",
        action: "toggleBold",
        icon: "BiBold",
    },
    italic: {
        title: "Italicize",
        action: "toggleItalic",
        icon: "BiItalic",
    },
    underline: {
        title: "Underline",
        action: "toggleUnderline",
        icon: "BiUnderline",
    },
    code: {
        title: "Code",
        action: "toggleCode",
        icon: "BiCode",
    },
    strike: {
        title: "Strike",
        action: "toggleStrike",
        icon: "BiStrikethrough",
    },
    superscript: {
        title: "Superscript",
        action: "toggleSuperscript",
        icon: "RiSuperscript2",
    },
    subscript: {
        title: "Subscript",
        action: "toggleSubscript",
        icon: "RiSubscript2",
    },
    clean: {
        title: "Clean",
        action: "unsetAllMarks",
        icon: "RiEraserFill",
    },
};

const block_elements = {
    paragraph: {
        title: "Paragraph",
        wrap: "paragraph",
        argument: {},
        icon: "BiParagraph",
    },

    h1: {
        title: "Header 1",
        wrap: "heading",
        argument: { level: 1 },
        icon: "BiHeading",
    },

    h2: {
        title: "Header 2",
        wrap: "heading",
        argument: { level: 2 },
        icon: "BiHeading",
    },

    h3: {
        title: "Header 3",
        wrap: "heading",
        argument: { level: 3 },
        icon: "BiHeading",
    },

    h4: {
        title: "Header 4",
        wrap: "heading",
        argument: { level: 4 },
        icon: "BiHeading",
    },

    blockquote: {
        title: "Quote",
        wrap: "blockquote",
        action: "toggleBlockquote",
        argument: {},
        icon: "FaQuoteLeft",
    },

    plain: {
        title: "Plain Text",
        wrap: "div",
        action: "setPlainText",
        argument: {},
        icon: "FaQuoteLeft",
    },
};

const table_options = {
    addColumnBefore: {
        icon: "RiInsertColumnLeft",
        title: "Add Column Left",
    },
    addColumnAfter: {
        icon: "RiInsertColumnRight",
        title: "Add Column Right",
    },
    deleteColumn: {
        icon: "RiDeleteColumn",
        title: "Delete Column",
    },
    addRowBefore: {
        icon: "RiInsertRowTop",
        title: "Add Row Above",
    },
    addRowAfter: {
        icon: "RiInsertRowBottom",
        title: "Add row Below",
    },
    deleteRow: {
        icon: "RiDeleteRow",
        title: "Delete Row",
    },
    deleteTable: {
        icon: "RiDeleteBin2Line",
        title: "Delete Table",
    },
    mergeCells: {
        icon: "RiMergeCellsHorizontal",
        title: "Merge Cells",
    },
    splitCell: {
        icon: "RiSplitCellsHorizontal",
        title: "Split Cells",
    },
};

const alignment_options = {
    left: {
        title: "Align Left",
        icon: "BiAlignLeft",
    },
    center: {
        title: "Align Center",
        icon: "BiAlignMiddle",
    },
    right: {
        title: "Align Right",
        icon: "BiAlignRight",
    },
    justify: {
        title: "Align Justify",
        icon: "BiAlignJustify",
    },
};

const insert_options = {
    hr: {
        title: "Insert Horizontal Rule",
        insert: "<hr/>",
        icon: "RiSeparator",
        action: "setHorizontalRule",
    },
};
