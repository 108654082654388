import Paragraph from "@tiptap/extension-paragraph";

var core = require('@tiptap/core');

const PlainText = Paragraph.extend({
    name: "plaintext",
    parseHTML() {
        return [
            { tag: 'div' },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', core.mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
    addCommands() {
        return {
            setPlainText: () => ({ commands }) => {
                return commands.setNode(this.name);
            },
        };
    },
});

export default PlainText;