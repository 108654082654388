import React, { useContext, useRef, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import Icon from "components/Icon";
import Loading from "components/Loading";

import Platform from "system/Platform";

import ProjectContext from "system/ProjectContext";

import FileMetaInputComponent from "./File/MetaInputComponent";
import FilePathComponent from "./File/PathComponent";
import EditorComponent from "./File/EditorComponent";
import FolderItemsComponents from "./File/FolderItemsComponents";
import TrashComponent from "./File/TrashComponent";
import FontSizeComponent from "./File/FontSizeComponent";

export default function MainPaneComponent(props) {
    const ref = useRef();
    const { files } = useContext(ProjectContext);
    const navigate = useNavigate();

    const display = props.action ?? false;
    let mode = props.mode;
    let file = false;

    if (props.fileId) {
        file = Platform.fileById(files, props.fileId);
        if (!file) {
            mode = "invalid";
            navigate("/project/" + props.project_id);
        } else {
            mode = file.type;
        }
    } else if (props.project_id) {
        //mode = "project";
    }

    const updateScroll = (e) => {
        const toolbar = document.getElementById("toolbar-container");
        if (ref.current && toolbar) {
            const bb = toolbar.getBoundingClientRect();
            console.log(ref.current.scrollTop, bb.top)
            if (ref.current.scrollTop >= bb.top) {
                ref.current.classList.add("fixed-toolbar");
            } else {
                ref.current.classList.remove("fixed-toolbar");
            }
        } else if (ref.current) {
            ref.current.classList.remove("fixed-toolbar");
        }
    };

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener("scroll", updateScroll);
            updateScroll();
        }
    }, [props]);

    const passProps = () => {
        return {
            fileId: props.fileId,
            //project: props.project,
            project_id: props.project_id,
        };
    };

    const TopBar = (props) => {
        return (
            <div id="status-bar" className="d-flex bg-secondary">
                {display !== false && (
                    <div className=" text-md-start">
                        <NavLink className="btn btn-default text-white" to={file.link}>
                            <Icon icon="BiArrowBack" /> Back
                        </NavLink>
                    </div>
                )}
                {display === false && (
                    <>
                        {props.settings === true && (
                            <div className="text-md-start">
                                <NavLink className="btn btn-default text-white" to={file.link + "/settings"}>
                                    <Icon icon="BiCog" /> File
                                </NavLink>
                            </div>
                        )}
                        {props.move === true && (
                            <div className="text-md-start">
                                <NavLink className="btn btn-default text-white" to={file.link + "/move"}>
                                    <Icon icon="BiLeftTopArrowCircle" /> Move {props.type}
                                </NavLink>
                            </div>
                        )}
                    </>
                )}

                <div className="flex-fill text-md-end">{props.fontSize && <FontSizeComponent />}</div>
            </div>
        );
    };

    const FileView = () => {
        return (
            <>
                <TopBar settings={true} move={true} fontSize={!display} />
                {display && (
                    <div id="content">
                        {display === "settings" && <FileMetaInputComponent {...passProps()} />}
                        {display === "move" && <FilePathComponent {...passProps()} />}
                    </div>
                )}

                {display === false && <EditorComponent {...passProps()} />}
            </>
        );
    };

    const FolderView = () => {
        return (
            <>
                <TopBar settings={true} move={true} />
                <div id="content">
                    {display === "settings" && <FileMetaInputComponent {...passProps()} />}
                    {display === "move" && <FilePathComponent {...passProps()} />}
                    {display === false && <FolderItemsComponents {...passProps()} />}
                </div>
            </>
        );
    };

    const ProjectView = () => {
        return <div id="content">{display === false && <FolderItemsComponents project_id={props.project_id} fileId={""} />}</div>;
    };

    const RenderView = () => {
        switch (mode) {
            case "file":
                return <FileView />;
            case "folder":
                return <FolderView />;
            case "project":
                return <ProjectView />;
            case "trash":
                return <TrashComponent {...props} />;
            case "loading":
                return <Loading>Loading Manuscriptus Project</Loading>;
            case "invalid":
                return (
                    <div className="alert alert-danger m-5">
                        <b>Invalid Resource.</b>
                        <br />
                        It may have been deleted or the link messed up.
                    </div>
                );
            default:
                return <div>unhandled "{mode}"</div>;
        }
    };

    return (
        <div id="main-pane">
            <div id="content-wrapper" ref={ref} key={props.fileId}>
                <RenderView />
            </div>
        </div>
    );
}
