import React, { useContext } from "react";

import ProjectContext from "../system/ProjectContext";

import { truncate } from "functions";

export default function MobileNavComponent(props) {
    const { project, getFile } = useContext(ProjectContext);
    let title = project.name;

    const file = getFile(props.fileId);
    if (file) {
        title = file.name;
    }

    title = truncate(title, 25);

    const toggleSidebar = () => {
        const leftPane = document.getElementById("left-pane");
        const shown = leftPane.classList.contains("show");
        if (shown) {
            leftPane.classList.remove("show");
        } else {
            leftPane.classList.add("show");
        }
    };

    return (
        <div className="navbar navbar-expand-lg navbar-light bg-light" id="navbar">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" onClick={() => toggleSidebar()}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <span className="navbar-brand">
                    {title}
                </span>
            </div>
        </div>
    );
}
