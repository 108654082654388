import React, { useContext, useEffect, useState } from "react";

import Platform from "../system/Platform";

import AuthContext from "../system/AuthContext";

import { useNavigate } from "react-router-dom";

import config from "config";

import Loading from "components/Loading";
import Icon from "components/Icon";

export default function LoginScreen(props) {
    const navigate = useNavigate();
    const { setLoggedIn } = useContext(AuthContext);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        Platform.auth
            .checkStatus()
            .then((response) => {
                if (response && response.data && response.data.user) {
                    setLoggedIn(response.data.user);
                } else {
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.error(error);
                if (error.response.status == 401) {
                    setIsLoaded(true);
                } else {
                    setIsLoaded("Internal Server Error");
                }
                return error;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isLoaded) {
        return <Loading>Loading Manuscriptus</Loading>;
    } else {
        return (
            <div className="d-flex h-100 align-content-center align-items-center">
                <div className="flex-fill text-center">
                    <p>
                        <a href={config.BASE_URL + "login"} className="btn btn-primary btn-lg btn-xl">
                            Login <Icon icon="bx-log-in" />
                        </a>
                    </p>
                    {typeof isLoaded == "string" && <div className="alert alert-danger">{isLoaded}</div>}
                </div>
            </div>
        );
    }
}
