import React from "react";
import * as BoxIcons from "react-icons/bi"; // BoxIcons
import * as FontAwesome from "react-icons/fa"; // FontAwesome
//import * as GameIcons from "react-icons/gi";
//import * as AntDesign from "react-icons/ai";
import * as RemixIcons from "react-icons/ri";
import RaIcons from "../icons/ra"; // RPG Awesome
import BxIcons from "../icons/bx"; // BoxIcons

export default function Icon(props) {
    let { icon = "", color, ...otherProps } = props;
    let Icon = FontAwesome.FaExclamationTriangle;

    if (icons.hasOwnProperty(icon.toLowerCase())) {
        Icon = icons[icon.toLowerCase()];
    } else {
        const prefix = icon.substring(0, 2);
        if(libs.hasOwnProperty(prefix)){
            const Library = libs[prefix];
            if(Library.icons.hasOwnProperty(icon)){
                if(typeof Library.icons[icon] === 'function'){
                    Icon = Library.icons[icon];
                } else {
                    console.log(icon, 'not string or ')
                }
            } else {
                console.log(icon, ':', icon, ','); 
            }
        } else {
            console.log('unknown icon lib', prefix);
        }
    }

    if(color){
        if(color.substring(0, 1) !== '#'){
            color = '#' + color;
        }
        otherProps.style = {color:color};
        otherProps.color = color;
    }


    return <Icon {...otherProps} data-icon={icon}  />;
}

export const libs = {
    Fa: {name: "FontAwesome", icons: FontAwesome, show: false, svg: true, url: "https://fontawesome.com/"},
    //Gi: {name: "GameIcons", icons: GameIcons, show: false, svg: true, url: "https://game-icons.net/"},
    Bi: {name: "BoxIcons", icons: BoxIcons, show: false, svg: true, url: "https://github.com/atisawd/boxicons"},
    //Ai: {name: "AntDesign", icons: AntDesign, show: false, url: "https://github.com/ant-design/ant-design-icons"}
    Ri: {name: "Remix Icon", icons: RemixIcons, show: false, svg: true, url: "https://github.com/Remix-Design/RemixIcon"},
    bx: {name: "BoxIcons", icons: BxIcons, show: true, svg: false, url: "https://github.com/atisawd/boxicons" },
    ra: {name: "RPG Awesome", icons: RaIcons, show: true, svg: false, url: "https://nagoshiashumari.github.io/Rpg-Awesome/" },
}

export const icons = {
    filetext: FontAwesome.FaRegFileWord,
    file: FontAwesome.FaRegFileAlt,
    folder: FontAwesome.FaFolder,
    book: FontAwesome.FaBook,
    bookopen: FontAwesome.FaBookOpen,
    briefcase: FontAwesome.FaBriefcase,
    calendar: BoxIcons.BiCalendar,
    paperclip: FontAwesome.FaPaperclip,
    feather: FontAwesome.FaFeather,
    pen: FontAwesome.FaPenAlt,
    fancypen: FontAwesome.FaPenFancy,
    pencil: FontAwesome.FaPencilAlt,
    envelope: FontAwesome.FaEnvelope,
    person: FontAwesome.FaUserCircle,
    location: FontAwesome.FaMapMarkerAlt
};

export const icon_colors = [
    '#000000', //black
    '#777777', //gray
    '#FF0000', //red
    '#0000FF', //blue
    '#00AA00', //green
    '#FFA500', // orange
    //'#FFFF00', 
    '#FF00FF', //pink
    '#964B00', //brown
    '#00AAFF', //light-blue
    '#FF88aa', //peach
    '#7F00FF', //purple
    '#007FFF', //blue-gray
    '#20b2aa', //light sea green
    //'#DFFF00',

];