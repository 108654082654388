import React from "react";

import Platform from "system/Platform";

import Loading from "components/Loading";

import Editor from "../../TipTapEditor/Editor";

// using component class due to componentWillUnmount availability
export default class EditorComponent extends React.Component {
    fileChangeTimeout = null;
    constructor(props) {
        super(props);
        this.state = {
            changed: false,
            fileContent: false,
            words: 0,
        };
        this.fileChange = this.fileChange.bind(this);
    }

    componentDidMount() {
        if (typeof this.props.fileId === "string") {
            Platform.system.readFile(this.props.fileId).then((response) => {
                //console.log("readFile", this.props.fileId, response);
                if (typeof response.data !== "string") {
                    response.data = JSON.stringify(response.data);
                }
                this.setState({ fileContent: response.data });
            });
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.fileChangeTimeout);
        if (this.state.changed) {
            this.updateFileContent(this.props.fileId, this.state.fileContent);
        }
    }

    fileChange(event) {
        const { editor } = event; // ,transaction
        //console.log("editor", editor);
        const value = editor.getHTML();

        this.setState({
            fileContent: value,
            changed: true,
            words: editor.storage.characterCount.words(),
        });
        if (typeof this.fileChangeTimeout === "number") {
            window.clearTimeout(this.fileChangeTimeout);
        }
        this.fileChangeTimeout = window.setTimeout(
            (fileId, value) => {
                this.updateFileContent(fileId, value);
            },
            5000,
            this.props.fileId,
            value
        );
    }

    updateFileContent(fileId, value) {
        if (typeof value !== "string") {
            value = JSON.stringify(value);
        }
        const params = { words: this.state.words };
        Platform.system.updateFileContent(fileId, value, params).then((response) => {
            //console.log("updateFileContent", response);
        });
    }

    render() {
        const editor_props = {
            id: this.props.fileId,
            value: this.state.fileContent,
            onChange: (event) => this.fileChange(event),
        };

        if (this.state.fileContent !== false) {
            return <Editor {...editor_props} />;
        } else {
            return <Loading>Loading File Content</Loading>;
        }
    }
}
