import Icon from "components/Icon";
import { Link } from "react-router-dom";

export default function Error404Screen(props) {
    document.title = "404 - Manuscriptus";

    return (
        <div className="App" id="LoginScreen">
            <div id="content">
                <h1><Icon icon="AlertTriangle" /> Error 404</h1>
                <p className="h3">"Toto, I've a feeling we're not in Kansas anymore."</p>
                <p>Malformed URL. Sorry.</p>
                <Link to="/" className="btn btn-primary btn-lg">
                    <Icon icon="home" /> Go Home
                </Link>
            </div>
        </div>
    );
}
