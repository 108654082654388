/**
 * Hack from @thaddeusjiang/react-sortable-list
 */
import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SortableItemProps } from "@thaddeusjiang/react-sortable-list";

export const SortableItemTr = (props: SortableItemProps) => {
    const { DragHandler, className } = props;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return DragHandler ? (
        <tr ref={setNodeRef} style={style} className={className}>
            <DragHandler {...attributes} {...listeners} />
            {props.children}
        </tr>
    ) : (
        <tr ref={setNodeRef} style={style} {...attributes} {...listeners} className={className}>
            {props.children}
        </tr>
    );
};
