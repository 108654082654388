import moment from "moment";

export function utcFormat(data_string) {
    return moment(data_string).utc().local().format("MM/DD/YYYY h:mm a");
}

export function numberFormat(num){
    if(isNaN(num) || !num){
        return "0";
    } else {
        return num.toLocaleString("en-US");
    }
}

export function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "…" : str;
}

export const jsonStorage = function(name, obj = {}) {
    obj.name = name;
    obj.get = function (default_value = {}) {
        try {
            const obj = JSON.parse(localStorage.getItem(this.name));
            return obj ? obj : default_value;
        } catch (e) {
            console.error(e);
            return default_value;
        }
    };
    obj.set = function (values = {}) {
        try {
            return localStorage.setItem(this.name, JSON.stringify(values));
        } catch (e) {
            console.error(e);
            return false;
        }
    };
    obj.find = function(key, default_value = false){
        const values = this.get();
        return typeof values[key] != 'undefined' ? values[key] : default_value;
    };
    obj.update = function (key, value) {
        const values = this.get();
        values[key] = value;
        this.set(values);
    };
    return obj;
}

export const wordCounts = jsonStorage("wordCounts", {
    sum: function () {
        const words = this.get();
        let sum = 0;
        for (let key in words) {
            sum += words[key];
        }
        return sum;
    },
});

export const collapsed = jsonStorage("collapsed");

function collapsedList() {
    try {
        const storage = localStorage.getItem("collapsed") ?? "{}";
        return JSON.parse(storage);
    } catch (e) {
        return {};
    }
}

export function _collapsed(id) {
    const list = collapsedList();
    if (typeof list[id] == "boolean") {
        return list[id];
    } else {
        return false;
    }
}

export function setCollapsed(id, val) {
    const list = collapsedList();
    list[id] = val;
    try {
        const json = JSON.stringify(list);
        localStorage.setItem("collapsed", json);
    } catch (e) {
        console.log("setCollapsed error", e, list);
        return {};
    }
}
