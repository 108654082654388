import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";

import PlainText from "./extensions/PlainText";

import Toolbar from "./Toolbar";

import { wordCounts } from "functions";

import "./styles.css";

const Tiptap = (props) => {

    const onChange = (event) => {
        wordCounts.update(props.id, event.editor.storage.characterCount.words());
        props.onChange(event);
    }

    const editor = useEditor({
        extensions: [
            StarterKit,
            Table.configure({
                resizable: true,
                HTMLAttributes: {
                    class: "table table-striped",
                },
            }),
            TextAlign.configure({
                types: ["heading", "paragraph"],
            }),
            PlainText,
            Underline,
            Superscript,
            Subscript,
            TableRow,
            TableHeader,
            TableCell, //CustomTableCell
            Placeholder.configure({
                placeholder: "Empty Document",
            }),
            CharacterCount,
        ],
        content: props.value,
        onUpdate: onChange,
    });

    return (
        <>
            <div className="tip-tap-toolbar-container" id="toolbar-container">
                <Toolbar editor={editor} />
            </div>
            <div className="editor">
                <EditorContent editor={editor} className="editor-content" />
            </div>
            {editor && <div className="word-count">{editor.storage.characterCount.words()} words</div>}
        </>
    );
};

export default Tiptap;
