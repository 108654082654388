const icons_list = [
    "bx-abacus",
    "bx-accessibility",
    "bx-add-to-queue",
    "bx-adjust",
    "bx-alarm-add",
    "bx-alarm-exclamation",
    "bx-alarm-off",
    "bx-alarm-snooze",
    "bx-alarm",
    "bx-album",
    "bx-align-justify",
    "bx-align-left",
    "bx-align-middle",
    "bx-align-right",
    "bx-analyse",
    "bx-anchor",
    "bx-angry",
    "bx-aperture",
    "bx-arch",
    "bx-archive-in",
    "bx-archive-out",
    "bx-archive",
    "bx-area",
    "bx-arrow-back",
    "bx-arrow-from-bottom",
    "bx-arrow-from-left",
    "bx-arrow-from-right",
    "bx-arrow-from-top",
    "bx-arrow-to-bottom",
    "bx-arrow-to-left",
    "bx-arrow-to-right",
    "bx-arrow-to-top",
    "bx-at",
    "bx-atom",
    "bx-award",
    "bx-badge-check",
    "bx-badge",
    "bx-baguette",
    "bx-ball",
    "bx-band-aid",
    "bx-bar-chart-alt-2",
    "bx-bar-chart-alt",
    "bx-bar-chart-square",
    "bx-bar-chart",
    "bx-barcode-reader",
    "bx-barcode",
    "bx-baseball",
    "bx-basket",
    "bx-basketball",
    "bx-bath",
    "bx-battery",
    "bx-bed",
    "bx-been-here",
    "bx-beer",
    "bx-bell-minus",
    "bx-bell-off",
    "bx-bell-plus",
    "bx-bell",
    "bx-bible",
    "bx-bitcoin",
    "bx-blanket",
    "bx-block",
    "bx-bluetooth",
    "bx-body",
    "bx-bold",
    "bx-bolt-circle",
    "bx-bomb",
    "bx-bone",
    "bx-bong",
    "bx-book-add",
    "bx-book-alt",
    "bx-book-bookmark",
    "bx-book-content",
    "bx-book-heart",
    "bx-book-open",
    "bx-book-reader",
    "bx-book",
    "bx-bookmark-alt-minus",
    "bx-bookmark-alt-plus",
    "bx-bookmark-alt",
    "bx-bookmark-heart",
    "bx-bookmark-minus",
    "bx-bookmark-plus",
    "bx-bookmark",
    "bx-bookmarks",
    "bx-border-all",
    "bx-border-bottom",
    "bx-border-inner",
    "bx-border-left",
    "bx-border-none",
    "bx-border-outer",
    "bx-border-radius",
    "bx-border-right",
    "bx-border-top",
    "bx-bot",
    "bx-bowl-hot",
    "bx-bowl-rice",
    "bx-bowling-ball",
    "bx-box",
    "bx-bracket",
    "bx-braille",
    "bx-brain",
    "bx-briefcase-alt-2",
    "bx-briefcase-alt",
    "bx-briefcase",
    "bx-brightness-half",
    "bx-brightness",
    "bx-broadcast",
    "bx-brush-alt",
    "bx-brush",
    "bx-bug-alt",
    "bx-bug",
    "bx-building-house",
    "bx-building",
    "bx-buildings",
    "bx-bulb",
    "bx-bullseye",
    "bx-buoy",
    "bx-bus-school",
    "bx-bus",
    "bx-cabinet",
    "bx-cable-car",
    "bx-cake",
    "bx-calculator",
    "bx-calendar-alt",
    "bx-calendar-check",
    "bx-calendar-edit",
    "bx-calendar-event",
    "bx-calendar-exclamation",
    "bx-calendar-heart",
    "bx-calendar-minus",
    "bx-calendar-plus",
    "bx-calendar-star",
    "bx-calendar-week",
    "bx-calendar-x",
    "bx-calendar",
    "bx-camera-home",
    "bx-camera-movie",
    "bx-camera-off",
    "bx-camera",
    "bx-candles",
    "bx-capsule",
    "bx-captions",
    "bx-car",
    "bx-card",
    "bx-caret-down-circle",
    "bx-caret-down-square",
    "bx-caret-down",
    "bx-caret-left-circle",
    "bx-caret-left-square",
    "bx-caret-left",
    "bx-caret-right-circle",
    "bx-caret-right-square",
    "bx-caret-right",
    "bx-caret-up-circle",
    "bx-caret-up-square",
    "bx-caret-up",
    "bx-carousel",
    "bx-cart-add",
    "bx-cart-alt",
    "bx-cart-download",
    "bx-cart",
    "bx-cast",
    "bx-category-alt",
    "bx-category",
    "bx-cctv",
    "bx-certification",
    "bx-chair",
    "bx-chalkboard",
    "bx-chart",
    "bx-chat",
    "bx-check-circle",
    "bx-check-double",
    "bx-check-shield",
    "bx-check-square",
    "bx-check",
    "bx-checkbox-checked",
    "bx-checkbox-minus",
    "bx-checkbox-square",
    "bx-checkbox",
    "bx-cheese",
    "bx-chevron-down-circle",
    "bx-chevron-down-square",
    "bx-chevron-down",
    "bx-chevron-left-circle",
    "bx-chevron-left-square",
    "bx-chevron-left",
    "bx-chevron-right-circle",
    "bx-chevron-right-square",
    "bx-chevron-right",
    "bx-chevron-up-circle",
    "bx-chevron-up-square",
    "bx-chevron-up",
    "bx-chevrons-down",
    "bx-chevrons-left",
    "bx-chevrons-right",
    "bx-chevrons-up",
    "bx-child",
    "bx-chip",
    "bx-church",
    "bx-circle-half",
    "bx-circle-quarter",
    "bx-circle-three-quarter",
    "bx-circle",
    "bx-clinic",
    "bx-clipboard",
    "bx-closet",
    "bx-cloud-download",
    "bx-cloud-drizzle",
    "bx-cloud-light-rain",
    "bx-cloud-lightning",
    "bx-cloud-rain",
    "bx-cloud-snow",
    "bx-cloud-upload",
    "bx-cloud",
    "bx-code-alt",
    "bx-code-block",
    "bx-code-curly",
    "bx-code",
    "bx-coffee-togo",
    "bx-coffee",
    "bx-cog",
    "bx-coin-stack",
    "bx-coin",
    "bx-collapse-alt",
    "bx-collapse-horizontal",
    "bx-collapse-vertical",
    "bx-collapse",
    "bx-collection",
    "bx-color-fill",
    "bx-color",
    "bx-columns",
    "bx-command",
    "bx-comment-add",
    "bx-comment-check",
    "bx-comment-detail",
    "bx-comment-dots",
    "bx-comment-edit",
    "bx-comment-error",
    "bx-comment-minus",
    "bx-comment-x",
    "bx-comment",
    "bx-compass",
    "bx-confused",
    "bx-conversation",
    "bx-cookie",
    "bx-cool",
    "bx-copy-alt",
    "bx-copy",
    "bx-copyright",
    "bx-credit-card-alt",
    "bx-credit-card-front",
    "bx-credit-card",
    "bx-cricket-ball",
    "bx-crop",
    "bx-cross",
    "bx-crosshair",
    "bx-crown",
    "bx-cube-alt",
    "bx-cube",
    "bx-cuboid",
    "bx-current-location",
    "bx-customize",
    "bx-cut",
    "bx-cycling",
    "bx-cylinder",
    "bx-data",
    "bx-desktop",
    "bx-detail",
    "bx-devices",
    "bx-dialpad-alt",
    "bx-dialpad",
    "bx-diamond",
    "bx-dice-1",
    "bx-dice-2",
    "bx-dice-3",
    "bx-dice-4",
    "bx-dice-5",
    "bx-dice-6",
    "bx-directions",
    "bx-disc",
    "bx-dish",
    "bx-dislike",
    "bx-dizzy",
    "bx-dna",
    "bx-dock-bottom",
    "bx-dock-left",
    "bx-dock-right",
    "bx-dock-top",
    "bx-dollar-circle",
    "bx-dollar",
    "bx-donate-blood",
    "bx-donate-heart",
    "bx-door-open",
    "bx-dots-horizontal-rounded",
    "bx-dots-horizontal",
    "bx-dots-vertical-rounded",
    "bx-dots-vertical",
    "bx-doughnut-chart",
    "bx-down-arrow-alt",
    "bx-down-arrow-circle",
    "bx-down-arrow",
    "bx-download",
    "bx-downvote",
    "bx-drink",
    "bx-droplet",
    "bx-dumbbell",
    "bx-duplicate",
    "bx-edit-alt",
    "bx-edit",
    "bx-envelope-open",
    "bx-envelope",
    "bx-equalizer",
    "bx-eraser",
    "bx-error-alt",
    "bx-error-circle",
    "bx-error",
    "bx-euro",
    "bx-exclude",
    "bx-exit-fullscreen",
    "bx-exit",
    "bx-expand-alt",
    "bx-expand-horizontal",
    "bx-expand-vertical",
    "bx-expand",
    "bx-export",
    "bx-extension",
    "bx-face",
    "bx-fast-forward-circle",
    "bx-fast-forward",
    "bx-female-sign",
    "bx-female",
    "bx-file-blank",
    "bx-file-find",
    "bx-file",
    "bx-film",
    "bx-filter-alt",
    "bx-filter",
    "bx-fingerprint",
    "bx-first-aid",
    "bx-first-page",
    "bx-flag",
    "bx-folder-minus",
    "bx-folder-open",
    "bx-folder-plus",
    "bx-folder",
    "bx-font-color",
    "bx-font-family",
    "bx-font-size",
    "bx-font",
    "bx-food-menu",
    "bx-food-tag",
    "bx-football",
    "bx-fork",
    "bx-fridge",
    "bx-fullscreen",
    "bx-game",
    "bx-gas-pump",
    "bx-ghost",
    "bx-gift",
    "bx-git-branch",
    "bx-git-commit",
    "bx-git-compare",
    "bx-git-merge",
    "bx-git-pull-request",
    "bx-git-repo-forked",
    "bx-glasses-alt",
    "bx-glasses",
    "bx-globe-alt",
    "bx-globe",
    "bx-grid-alt",
    "bx-grid-horizontal",
    "bx-grid-small",
    "bx-grid-vertical",
    "bx-grid",
    "bx-group",
    "bx-handicap",
    "bx-happy-alt",
    "bx-happy-beaming",
    "bx-happy-heart-eyes",
    "bx-happy",
    "bx-hard-hat",
    "bx-hash",
    "bx-hdd",
    "bx-heading",
    "bx-headphone",
    "bx-health",
    "bx-heart-circle",
    "bx-heart-square",
    "bx-heart",
    "bx-help-circle",
    "bx-hide",
    "bx-highlight",
    "bx-history",
    "bx-hive",
    "bx-home-alt-2",
    "bx-home-alt",
    "bx-home-circle",
    "bx-home-heart",
    "bx-home-smile",
    "bx-home",
    "bx-horizontal-center",
    "bx-horizontal-left",
    "bx-horizontal-right",
    "bx-hotel",
    "bx-hourglass",
    "bx-id-card",
    "bx-image-add",
    "bx-image-alt",
    "bx-image",
    "bx-images",
    "bx-import",
    "bx-infinite",
    "bx-info-circle",
    "bx-info-square",
    "bx-injection",
    "bx-intersect",
    "bx-italic",
    "bx-joystick-alt",
    "bx-joystick-button",
    "bx-joystick",
    "bx-key",
    "bx-knife",
    "bx-label",
    "bx-landscape",
    "bx-laptop",
    "bx-last-page",
    "bx-laugh",
    "bx-layer-minus",
    "bx-layer-plus",
    "bx-layer",
    "bx-layout",
    "bx-leaf",
    "bx-left-arrow-alt",
    "bx-left-arrow-circle",
    "bx-left-arrow",
    "bx-left-down-arrow-circle",
    "bx-left-indent",
    "bx-left-top-arrow-circle",
    "bx-lemon",
    "bx-library",
    "bx-like",
    "bx-line-chart-down",
    "bx-line-chart",
    "bx-link-alt",
    "bx-link-external",
    "bx-link",
    "bx-lira",
    "bx-list-check",
    "bx-list-minus",
    "bx-list-ol",
    "bx-list-plus",
    "bx-list-ul",
    "bx-loader-alt",
    "bx-loader-circle",
    "bx-loader",
    "bx-location-plus",
    "bx-lock-alt",
    "bx-lock-open-alt",
    "bx-lock-open",
    "bx-lock",
    "bx-log-in-circle",
    "bx-log-in",
    "bx-log-out-circle",
    "bx-log-out",
    "bx-low-vision",
    "bx-magnet",
    "bx-mail-send",
    "bx-male-female",
    "bx-male-sign",
    "bx-male",
    "bx-map-alt",
    "bx-map-pin",
    "bx-map",
    "bx-mask",
    "bx-math",
    "bx-medal",
    "bx-meh-alt",
    "bx-meh-blank",
    "bx-meh",
    "bx-memory-card",
    "bx-menu-alt-left",
    "bx-menu-alt-right",
    "bx-menu",
    "bx-merge",
    "bx-message-add",
    "bx-message-alt-add",
    "bx-message-alt-check",
    "bx-message-alt-detail",
    "bx-message-alt-dots",
    "bx-message-alt-edit",
    "bx-message-alt-error",
    "bx-message-alt-minus",
    "bx-message-alt-x",
    "bx-message-alt",
    "bx-message-check",
    "bx-message-detail",
    "bx-message-dots",
    "bx-message-edit",
    "bx-message-error",
    "bx-message-minus",
    "bx-message-rounded-add",
    "bx-message-rounded-check",
    "bx-message-rounded-detail",
    "bx-message-rounded-dots",
    "bx-message-rounded-edit",
    "bx-message-rounded-error",
    "bx-message-rounded-minus",
    "bx-message-rounded-x",
    "bx-message-rounded",
    "bx-message-square-add",
    "bx-message-square-check",
    "bx-message-square-detail",
    "bx-message-square-dots",
    "bx-message-square-edit",
    "bx-message-square-error",
    "bx-message-square-minus",
    "bx-message-square-x",
    "bx-message-square",
    "bx-message-x",
    "bx-message",
    "bx-meteor",
    "bx-microchip",
    "bx-microphone-off",
    "bx-microphone",
    "bx-minus-back",
    "bx-minus-circle",
    "bx-minus-front",
    "bx-minus",
    "bx-mobile-alt",
    "bx-mobile-landscape",
    "bx-mobile-vibration",
    "bx-mobile",
    "bx-money-withdraw",
    "bx-money",
    "bx-moon",
    "bx-mouse-alt",
    "bx-mouse",
    "bx-move-horizontal",
    "bx-move-vertical",
    "bx-move",
    "bx-movie-play",
    "bx-movie",
    "bx-music",
    "bx-navigation",
    "bx-network-chart",
    "bx-news",
    "bx-no-entry",
    "bx-no-signal",
    "bx-note",
    "bx-notepad",
    "bx-notification-off",
    "bx-notification",
    "bx-objects-horizontal-center",
    "bx-objects-horizontal-left",
    "bx-objects-horizontal-right",
    "bx-objects-vertical-bottom",
    "bx-objects-vertical-center",
    "bx-objects-vertical-top",
    "bx-outline",
    "bx-package",
    "bx-paint-roll",
    "bx-paint",
    "bx-palette",
    "bx-paper-plane",
    "bx-paperclip",
    "bx-paragraph",
    "bx-party",
    "bx-paste",
    "bx-pause-circle",
    "bx-pause",
    "bx-pen",
    "bx-pencil",
    "bx-phone-call",
    "bx-phone-incoming",
    "bx-phone-off",
    "bx-phone-outgoing",
    "bx-phone",
    "bx-photo-album",
    "bx-pie-chart-alt-2",
    "bx-pie-chart-alt",
    "bx-pie-chart",
    "bx-pin",
    "bx-planet",
    "bx-play-circle",
    "bx-play",
    "bx-plug",
    "bx-plus-circle",
    "bx-plus-medical",
    "bx-plus",
    "bx-podcast",
    "bx-pointer",
    "bx-poll",
    "bx-polygon",
    "bx-popsicle",
    "bx-pound",
    "bx-power-off",
    "bx-printer",
    "bx-pulse",
    "bx-purchase-tag-alt",
    "bx-purchase-tag",
    "bx-pyramid",
    "bx-qr-scan",
    "bx-qr",
    "bx-question-mark",
    "bx-radar",
    "bx-radio-circle-marked",
    "bx-radio-circle",
    "bx-radio",
    "bx-receipt",
    "bx-rectangle",
    "bx-recycle",
    "bx-redo",
    "bx-reflect-horizontal",
    "bx-reflect-vertical",
    "bx-refresh",
    "bx-registered",
    "bx-rename",
    "bx-repeat",
    "bx-reply-all",
    "bx-reply",
    "bx-repost",
    "bx-reset",
    "bx-restaurant",
    "bx-revision",
    "bx-rewind-circle",
    "bx-rewind",
    "bx-rfid",
    "bx-right-arrow-alt",
    "bx-right-arrow-circle",
    "bx-right-arrow",
    "bx-right-down-arrow-circle",
    "bx-right-indent",
    "bx-right-top-arrow-circle",
    "bx-rocket",
    "bx-rotate-left",
    "bx-rotate-right",
    "bx-rss",
    "bx-ruble",
    "bx-ruler",
    "bx-run",
    "bx-rupee",
    "bx-sad",
    "bx-save",
    "bx-scan",
    "bx-scatter-chart",
    "bx-screenshot",
    "bx-search-alt-2",
    "bx-search-alt",
    "bx-search",
    "bx-select-multiple",
    "bx-selection",
    "bx-send",
    "bx-server",
    "bx-shape-circle",
    "bx-shape-polygon",
    "bx-shape-square",
    "bx-shape-triangle",
    "bx-share-alt",
    "bx-share",
    "bx-shekel",
    "bx-shield-alt-2",
    "bx-shield-alt",
    "bx-shield-minus",
    "bx-shield-plus",
    "bx-shield-quarter",
    "bx-shield-x",
    "bx-shield",
    "bx-shocked",
    "bx-shopping-bag",
    "bx-show-alt",
    "bx-show",
    "bx-shower",
    "bx-shuffle",
    "bx-sidebar",
    "bx-signal-1",
    "bx-signal-2",
    "bx-signal-3",
    "bx-signal-4",
    "bx-signal-5",
    "bx-sitemap",
    "bx-skip-next-circle",
    "bx-skip-next",
    "bx-skip-previous-circle",
    "bx-skip-previous",
    "bx-sleepy",
    "bx-slider-alt",
    "bx-slider",
    "bx-slideshow",
    "bx-smile",
    "bx-sort-a-z",
    "bx-sort-alt-2",
    "bx-sort-down",
    "bx-sort-up",
    "bx-sort-z-a",
    "bx-sort",
    "bx-spa",
    "bx-space-bar",
    "bx-speaker",
    "bx-spray-can",
    "bx-spreadsheet",
    "bx-square-rounded",
    "bx-square",
    "bx-star",
    "bx-station",
    "bx-stats",
    "bx-sticker",
    "bx-stop-circle",
    "bx-stop",
    "bx-stopwatch",
    "bx-store-alt",
    "bx-store",
    "bx-street-view",
    "bx-strikethrough",
    "bx-subdirectory-left",
    "bx-subdirectory-right",
    "bx-sun",
    "bx-support",
    "bx-sushi",
    "bx-swim",
    "bx-sync",
    "bx-tab",
    "bx-table",
    "bx-tachometer",
    "bx-tag-alt",
    "bx-tag",
    "bx-target-lock",
    "bx-task-x",
    "bx-task",
    "bx-taxi",
    "bx-tennis-ball",
    "bx-terminal",
    "bx-test-tube",
    "bx-text",
    "bx-time-five",
    "bx-time",
    "bx-timer",
    "bx-tired",
    "bx-toggle-left",
    "bx-toggle-right",
    "bx-tone",
    "bx-traffic-cone",
    "bx-train",
    "bx-transfer-alt",
    "bx-transfer",
    "bx-trash-alt",
    "bx-trash",
    "bx-trending-down",
    "bx-trending-up",
    "bx-trim",
    "bx-trip",
    "bx-trophy",
    "bx-tv",
    "bx-underline",
    "bx-undo",
    "bx-unite",
    "bx-universal-access",
    "bx-unlink",
    "bx-up-arrow-alt",
    "bx-up-arrow-circle",
    "bx-up-arrow",
    "bx-upload",
    "bx-upside-down",
    "bx-upvote",
    "bx-usb",
    "bx-user-check",
    "bx-user-circle",
    "bx-user-minus",
    "bx-user-pin",
    "bx-user-plus",
    "bx-user-voice",
    "bx-user-x",
    "bx-user",
    "bx-vector",
    "bx-vertical-bottom",
    "bx-vertical-center",
    "bx-vertical-top",
    "bx-vial",
    "bx-video-off",
    "bx-video-plus",
    "bx-video-recording",
    "bx-video",
    "bx-voicemail",
    "bx-volume-full",
    "bx-volume-low",
    "bx-volume-mute",
    "bx-volume",
    "bx-walk",
    "bx-wallet-alt",
    "bx-wallet",
    "bx-water",
    "bx-webcam",
    "bx-wifi-0",
    "bx-wifi-1",
    "bx-wifi-2",
    "bx-wifi-off",
    "bx-wifi",
    "bx-wind",
    "bx-window-alt",
    "bx-window-close",
    "bx-window-open",
    "bx-window",
    "bx-windows",
    "bx-wine",
    "bx-wink-smile",
    "bx-wink-tongue",
    "bx-won",
    "bx-world",
    "bx-wrench",
    "bx-x-circle",
    "bx-x",
    "bx-yen",
    "bx-zoom-in",
    "bx-zoom-out",
];

const icons = {};
icons_list.forEach((icon) => {
    icons[icon] = (props) => {
        return <i className={`bx ${icon} ra-fw`} {...props}  />; //use ra-fw class because bx-fw is crap
    };
});

export default icons;