import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Platform from "../system/Platform";

import Loading from "../components/Loading";

import LeftPaneComponent from "../components/Panes/LeftPaneComponent";
import RightPaneComponent from "../components/Panes/RightPaneComponent";
import MainPaneComponent from "../components/Panes/MainPaneComponent";
import ProjectPaneComponent from "../components/Panes/ProjectPaneComponent";
import MobileNavComponent from "components/MobileNavComponent";

import ProjectContext from "../system/ProjectContext";

import themes from "../system/themes";
import { wordCounts } from "functions";

export default function ProjectScreen(props) {
    const [project, setProject] = useState(false);
    const [files, setFiles] = useState(false);
    const [viewConfig, setViewConfig] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem("theme") ?? "Default");
    const ref = useRef(null);

    const navigate = useNavigate();

    const getFile = (fileId) => {
        for (const idx in files) {
            if (files[idx].id === fileId) {
                return files[idx];
            }
        }
        return false;
    };

    const setFile = (file) => {
        const newFiles = files.map((_file) => {
            return _file.id === file.id ? file : _file;
        });
        setFiles(newFiles);
    };

    const setOrder = (fileId, order) => {
        const file = getFile(fileId);
        const _file = { ...file };
        _file.sort_order = order;
        setFile(_file);
    };

    const appendNewFile = (file) => {
        const newFile = Platform.system.standardizeFile(file);
        const newFiles = [...files];
        newFiles.push(newFile);
        setFiles(newFiles);
        return newFile;
    };

    const updateViewConfig = (prop, value) => {
        const _config = { ...viewConfig };
        _config[prop] = value;
        setViewConfig(_config);
    };

    const context = {
        project: project,
        setProject: setProject,
        files: files,
        setFiles: setFiles,
        getFile: getFile,
        setFile: setFile,
        appendNewFile: appendNewFile,
        addFolder: (name, parent_id) => {
            Platform.system.createFolder(props.project_id, name, "", [parent_id]).then((response) => {
                const newFile = appendNewFile(response.data);
                navigate(newFile.link);
            });
        },
        addFile: (name, parent_id) => {
            Platform.system.createFile(props.project_id, name, "", [parent_id]).then((response) => {
                const newFile = appendNewFile(response.data);
                navigate(newFile.link);
            });
        },
        deleteFile: (fileId) => {
            const file = getFile(fileId);
            const parent_id = file.parent_id;
            const newFiles = files.filter((file) => {
                return file.id !== fileId;
            });
            setFiles(newFiles);

            Platform.system.deleteFile(fileId);
            if (parent_id) {
                navigate(getFile(parent_id).link);
            } else {
                navigate(project.link);
            }
        },
        moveFile: (file, parent_id) => {
            if (
                file.parent_id !== parent_id && // didn't move
                file.id !== parent_id // don't create a gandfather paradox
            ) {
                console.log("move file", file.name);
                Platform.system.moveFile(file, parent_id).then((response) => {
                    if (response.data) {
                        console.log("fileMove", response.data);
                        setFile(Platform.system.standardizeFile(response.data));
                    } else {
                        console.log("fileMove error?", response);
                    }
                });
            }
        },
        updateFilesOrder: (updates) => {
            const bulk_update = [];
            const _files = files.map((_file) => {
                if (updates.hasOwnProperty(_file.id)) {
                    _file.sort_order = updates[_file.id];
                    bulk_update.push({ id: _file.id, sort_order: _file.sort_order });
                }
                return _file;
            });
            setFiles(Platform.system.standardizeFiles(_files));
            Platform.system.bulkUpdateFileMeta(props.project_id, bulk_update);
        },
        setOrder: setOrder,
        duplicateFile: function (file, name, parent_id = null) {
            const data = { name: name, template: false };
            if (parent_id !== null) {
                data.parent_id = parent_id;
            }
            Platform.system.copyFile(file, data).then((response) => {
                if (response.data.files) {
                    setFiles(Platform.system.standardizeFiles(response.data.files));
                } else if (document.data.id) {
                    const newFile = appendNewFile(response.data);
                    navigate(newFile.link);
                } else {
                    console.log("unhandled duplicateFile", response.data);
                }
            });
        },
        viewConfig: viewConfig,
        setViewConfig: setViewConfig,
        updateViewConfig: updateViewConfig,
        theme: theme,
        setTheme: (name) => {
            if (themes.hasOwnProperty(name)) {
                setTheme(name);
                localStorage.setItem("theme", name);
            }
        },
        templates: () => {
            return files.filter((file) => {
                return file.template;
            });
        }
    };

    useEffect(() => {
        Platform.system.getProject(props.project_id).then((response) => {
            const p = response.data;
            p.icon = "Book";
            p.type = "project";
            p.link = "/project/" + props.project_id + "/view";
            setProject(p);
            setFiles(Platform.system.standardizeFiles(p.documents));

            const _wordCounts = {};
            p.documents.forEach((document) => {
                _wordCounts[document.id] = parseInt(document.words);
            });
            wordCounts.set(_wordCounts);

            setIsLoaded(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.project_id]);

    useEffect(() => {
        if (project.name) {
            document.title = project.name + " - Manuscriptus Project";
        }
    }, [project, project.name]);

    useEffect(() => {
        if (files && !isLoaded) {
            setFiles(Platform.system.standardizeFiles(files));
            setIsLoaded(true);
        }
    }, [files, isLoaded]);

    const updateScroll = (e) => {
        const toolbar = document.getElementById("toolbar-container");
        if (ref.current && toolbar) {
            const bb = toolbar.getBoundingClientRect();
            console.log(ref.current.scrollTop, bb.top);
            if (bb.top < 1) {
                ref.current.classList.add("fixed-toolbar");
            } else {
                ref.current.classList.remove("fixed-toolbar");
            }
        } else if (ref.current) {
            ref.current.classList.remove("fixed-toolbar");
        }
    };

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener("scroll", updateScroll);
            updateScroll();
        }
    }, [isLoaded]);

    const RenderPane = () => {
        switch (props.mode) {
            case "project_settings":
                return <ProjectPaneComponent {...props} />;
            case "trash":
                return <MainPaneComponent {...props} />;
            case "file":
            case "folder":
            case "project":
            default:
                return <MainPaneComponent {...props} />;
        }
    };

    if (isLoaded) {
        return (
            <ProjectContext.Provider value={context}>
                <div className="App" ref={ref}>
                    <MobileNavComponent {...props} />
                    <LeftPaneComponent {...props} />
                    <RenderPane />
                    <RightPaneComponent {...props} />
                </div>
            </ProjectContext.Provider>
        );
    } else {
        return <Loading>Loading Manuscriptus</Loading>;
    }
}
