import axios from "axios";

import Platform from "./Platform";

import config from "config";

const Manuscriptus = {
    name: "Manuscriptus",
    client: axios.create({
        baseURL: config.BASE_URL + "api",
        withCredentials: true
    }),
    /**
     * Requests
     */
    getProject: function (id, params = {}) {
        params.id = id;
        return this._get("/project", params);
    },
    createProject: function (name, data = {}) {
        data.name = name;
        data.description = "New Manuscriptus project.";
        return this._post("/project/create", data);
    },
    updateProject: function (id, data) {
        data.id = id;
        return this._post("/project/update", data);
    },
    createFolder: function (project_id, name, description = "", parents, data = {}, params = {}) {
        data.type = "folder";
        data.icon = "folder";
        return this.createFile(project_id, name, description, parents, data, params);
    },
    createFile: function (project_id, name, description = "", parents, data = {}, params = {}) {
        data.name = name;
        data.description = description;
        data.parent_id = parents.join(" ");
        data.project_id = project_id;
        params.doc = name;
        return this._post("/document/create", data, params);
    },

    listProjects: function () {
        return this._get("/projects", {});
    },

    /**
     * Move file/folder to different parent
     * @param {object} file
     * @param {string} toId
     * @returns {Promise}
     */
    moveFile: function (file, toId) {
        const data = {
            id: file.id,
            parent_id: toId,
            sort_order: 9999,
        };
        return this._post("/document/move", data);
    },
    /**
     * Alias for moveFile since it's the same for Drive
     * @param {object} file
     * @param {string} toId
     * @returns {Promise}
     */
    moveFolder: function (file, toId) {
        return this.moveFile(file, toId);
    },
    copyFile: function (file, data = {}) {
        data.id = file.id;
        return this._post(`/document/copy`, data);
    },
    updateFileMeta: function (document_id, data = {}, params = {}) {
        data.id = document_id;
        return this._post("/document/update", data, params);
    },
    bulkUpdateFileMeta: function (project_id, documents, data = {}, params = {}) {
        data.project_id = project_id;
        data.documents = documents;
        return this._post("/document/bulk_update", data, params);
    },
    listFiles: function (project_id, params = {}) {
        params.project_id = project_id;
        return this._get("/project/documents", params);
    },
    getFile: function (document_id, params = {}) {
        params.id = document_id;
        return this._get("/document", params);
    },
    readFile: function (document_id, params = {}) {
        params.id = document_id;
        return this._get("/document/content", params);
    },
    readFileSafe: function (document_id, params = {}) {
        params.id = document_id;
        return this._get("/document/content_safe", params);
    },
    readFileRaw: function (document_id, params = {}) {
        params.id = document_id;
        return this._get("/document/content", params, {
            transformResponse: (res) => {
                return res;
            },
            headers: {
                accept: "text/plain",
            },
        });
    },
    updateFileContent: function (document_id, content, params = {}) {
        params.id = document_id;
        return this._post("/document/content/update", content, params);
    },
    deleteFile: function (document_id, data = {}) {
        data.id = document_id;
        return this._post("/document/delete", data);
    },
    undeleteFile: function (document_id, data = {}) {
        data.id = document_id;
        return this._post("/document/undelete", data);
    },

    /**
     * Mutators
     */

    toPaths: function (files, project_id) {
        let paths = [
            {
                id: "",
                path: "/",
            },
        ];
        return this._paths(Platform.toTree(files, project_id), "/", paths);
    },

    _paths: function (tree, path = "/", paths = []) {
        tree.forEach((file) => {
            if (file.type === "folder") {
                paths.push({
                    path: path + file.name,
                    id: file.id,
                });
                if (file.children) {
                    paths = this._paths(file.children, path + file.name + "/", paths);
                }
            }
        });
        return paths;
    },

    /**
     * Convert the files array returned by Google Drive into what platform expects
     * @param {object} config the project configuration contianing `order`
     * @param {array} files
     * @returns {array}
     */
    standardizeFiles(files) {
        return files
            .map((file) => {
                return this.standardizeFile(file);
            })
            .sort((a, b) => a.sort_order - b.sort_order);
    },

    standardizeFile(F) {
        F.link = `/project/${F.project_id}/file/${F.id}`;
        F.show = true;
        F.sort_order = parseInt(F.sort_order);
        F.canDelete = F.parent_id ? true : false;
        return F;
    },

    projectFile(files, project_id) {
        console.log("projectFile junk");
        for (var idx in files) {
            if (files[idx].parent_id === 0) {
                return files[0];
            }
        }
        return false;
    },

    /**
     * Request methods
     */
    _get: function (endpoint, params = {}, options = {}) {
        //params.token = this._access_token();
        options.method = "get";
        options.url = endpoint;
        options.params = params;
        if (options.headers) {
            options.headers = { ...this._headers(), ...options.headers };
        } else {
            options.headers = this._headers();
        }
        return this.client.request(options).catch(this._catch401).then(this._errors);
    },
    _post: function (endpoint, data = {}, params = {}, options = {}) {
        options.method = "post";
        options.url = endpoint;
        options.data = data;
        options.params = params;
        if (options.headers) {
            options.headers = { ...this._headers(), ...options.headers };
        } else {
            options.headers = this._headers();
        }
        return this.client.request(options).catch(this._catch401).then(this._errors);
    },

    _headers: function (options = {}) {
        options["accept"] = "application/json";
        //options["Authorization"] = this._Authorization();
        return options;
    },
    _Authorization: function () {
        return "Bearer " + this._access_token();
    },
    _access_token: function () {
        const credentials = Platform.auth.getCredentials();
        if (!credentials) {
            console.log("No Credentials!!!");
        } else {
            return credentials.access_token;
        }
    },
    _errors: function (response) {
        if (typeof response === "undefined") {
            console.log("CORs error");
            response = {
                data: {},
            };
        }
        if (response.data.error) {
            alert(response.data.error);
        }
        return response;
    },
    _catch401: function (error) {
        console.log('error', error);
        if (error.response.status === 401) {
            Platform.logout();
        }
        return error;
    },
};

export default Manuscriptus;
