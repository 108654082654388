import React, { useState, useContext } from "react";

import Platform from "system/Platform";

import ProjectContext from "system/ProjectContext";

export default function PathComponent(props) {
    const { files, getFile, moveFile } = useContext(ProjectContext);
    const file = getFile(props.fileId);
    const [parent_id, setparent_id] = useState(file.parent_id);

    const handleChange = (event) => {
        setparent_id(event.target.value);
        moveFile(file, event.target.value);
    };

    const paths = Platform.system.toPaths(files, props.project_id);
    //console.log("paths", paths);

    return (
        <div className="card card-body m-4">
            <p>Move this {file.type} to a different folder.</p>
            <div className="input-group mb-3">
                <label className="input-group-text">Path:</label>
                <select name="parent_id" value={parent_id} onChange={(e) => handleChange(e)} className="form-control">
                    {paths.map((path) => {
                        if (path.id === props.fileId) {
                            return null;
                        }
                        return (
                            <option value={path.id} key={path.id}>
                                {path.path}
                            </option>
                        );
                    })}
                </select>
            </div>
            <p className="small"><em>Note: Files can be dragged/dropped in the left pane.</em></p>
        </div>
    );
}
