import React, { useRef, useEffect, useState, useContext } from "react";
import Icon from "components/Icon";
import { NavLink } from "react-router-dom";

import { collapsed } from "functions";

import ProjectContext from "system/ProjectContext";

export default function FileItemComponent(props) {
    const { file } = props;
    const ref = useRef(null);
    const [dragOver, setDragOver] = useState(false);

    let _expanded = !collapsed.find(file.id);

    if (file.type === "project") {
        _expanded = true;
    }
    const [expanded, setExpanded] = useState(_expanded);
    const { getFile, moveFile } = useContext(ProjectContext);

    const isFolder = file.type === "folder";
    const isProject = file.type === "project";
    const id = isProject ? "" : file.id;

    useEffect(() => {
        const handleDragStart = (event) => {
            event.dataTransfer.effectAllowed = "move";
            event.dataTransfer.setData("text/plain", event.target.dataset.id);
        };

        const handleDragOver = (event) => {
            event.preventDefault();
        };

        const handleDragEnter = (event) => {
            event.preventDefault();
            setDragOver(true);
        };

        const handleDragLeave = (event) => {
            event.preventDefault();
            setDragOver(false);
        };

        const handleDrop = (event) => {
            event.preventDefault();
            const dropId = event.dataTransfer.getData("text/plain");
            const destFile = getFile(dropId);
            moveFile(destFile, id);
        };

        const handleDragEnd = (event) => {
            event.preventDefault();
        };

        ref.current.addEventListener("dragstart", handleDragStart);
        ref.current.addEventListener("dragend", handleDragEnd);
        ref.current.addEventListener("drop", handleDrop);
        if (isFolder || isProject) {
            ref.current.addEventListener("dragover", handleDragOver);
            ref.current.addEventListener("dragenter", handleDragEnter);
            ref.current.addEventListener("dragleave", handleDragLeave);
        }

        ref.current.addEventListener("click", () => {
            document.getElementById("left-pane").classList.remove("show");
        });
        // eslint-disable-next-line
    }, [file, moveFile, getFile]);

    const toggleExpanded = () => {
        collapsed.update(file.id, expanded ? true : false);
        setExpanded(!expanded);
    };

    const className = dragOver ? "drag-over" : "";

    return (
        <div className="list-item">
            <div className="d-flex align-items-center">
                {isFolder && props.children.length > 0 && <span onClick={() => toggleExpanded()} className={"bx bx-chevron-" + (expanded ? "down" : "up")} role="button"></span>}
                {isFolder && props.children.length < 1 && <i className="bx bx-chevron-right "></i>}
                <div className="flex-grow-1">
                    <NavLink to={file.link} data-id={id} data-type={file.type} data-link={file.link} ref={ref} draggable={true} className={className}>
                        <Icon icon={file.icon} color={file.icon_color} /> {file.name}
                    </NavLink>
                </div>
            </div>

            {(isFolder || isProject) && expanded && <div className="list-item-children">{props.children}</div>}
        </div>
    );
}
