import React, { useContext, useEffect, useState, useRef } from "react";

import ProjectContext from "system/ProjectContext";

import Loading from "components/Loading";

import Icon from "components/Icon";
import Platform from "system/Platform";

export default function RightPaneComponent(props) {
    const { project, files, getFile, viewConfig, updateViewConfig } = useContext(ProjectContext);
    const [width, setWidth] = useState(parseInt(localStorage.getItem("rightPaneWidth") ?? 250));
    const [file, setFile] = useState(false);
    const [content, setContent] = useState(false);

    const resize = useRef(null);

    let mode = false;
    if (file) {
        mode = "file";
    }

    useEffect(() => {
        if (viewConfig.right_pane_document_id) {
            setFile(getFile(viewConfig.right_pane_document_id));
        } else {
            setFile(false);
        }
    }, [viewConfig, files]);

    useEffect(() => {
        if (resize.current) {
            const handleDrag = (event) => {
                const w = window.innerWidth - event.clientX;

                if (event.clientX > 250 && w > 200) {
                    setWidth(w);
                    localStorage.setItem("rightPaneWidth", w);
                }
            };

            resize.current.addEventListener("drag", handleDrag);
        }
    }, [file]);

    const fetchContent = () => {
        setContent(false);
        if (file.id) {
            Platform.system.readFileSafe(file.id).then((response) => {
                if (response.data) {
                    setContent(response.data);
                } else {
                    setContent("error");
                }
            });
        }
    };

    useEffect(() => {
        fetchContent();
    }, [file]);

    const hideSidebar = () => {
        document.getElementById("right-pane").classList.remove("show");
    };

    if (mode == "file" && file) {
        return (
            <>
                <div id="right-pane" className="bg-light" style={{ width: width }}>
                    <div id="right-pane-header">
                        <div className="d-flex align-items-center bg-secondary text-white">
                            <div className="text-md-start px-2">{file.name}</div>
                            <div className="flex-fill text-md-end">
                                <button onClick={() => fetchContent()} className="btn btn-default text-white">
                                    <Icon icon="bx-refresh" />
                                    Refresh
                                </button>
                                <button onClick={() => updateViewConfig("right_pane_document_id", false)} className="btn btn-default text-white">
                                    <Icon icon="bx-x-circle" />
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="right-pane-main">
                        {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
                        {!content && <Loading>Loading Content</Loading>}
                    </div>
                    <div id="right-pane-resize" ref={resize} draggable={true}></div>
                </div>
            </>
        );
    } else {
        return false;
    }
}
