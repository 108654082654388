import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import CardComponent from "components/CardComponent";
import ProjectContext from "system/ProjectContext";

import Icon from "components/Icon";

import Platform from "system/Platform";

import { utcFormat, wordCounts } from "functions";

export default function ProjectPaneComponent(props) {
    const { project, setProject } = useContext(ProjectContext);

    const [name, setName] = useState(project.name);
    const [description, setDescription] = useState(project.description);

    const save = () => {
        Platform.system
            .updateProject(props.project_id, {
                name: name,
                description: description,
            })
            .then(() => {
                let localProject = { ...project };
                localProject.name = name;
                localProject.description = description;
                setProject(localProject);
            });
    };

    return (
        <div id="main-pane">
            <div id="content-wrapper" className="p-4">
                <div className="c2">
                    <CardComponent header={<h3 className="m-0">Information</h3>} className="mb-4">
                        Project created {utcFormat(project.created_at)}<br/>

                        {wordCounts.sum()} words
                    </CardComponent>
                    <CardComponent header={<h3 className="m-0">Actions</h3>} className="mb-4">
                        <NavLink to={`/`} className="btn btn-primary m-1">
                            <Icon icon="FaList" /> Return to Project List
                        </NavLink>
                        <a href="https://manuscriptus.net/" className="btn btn-secondary m-1">
                            <Icon icon="FaWindowClose" /> Exit
                        </a>
                    </CardComponent>
                    <CardComponent header={<h3 className="m-0">Project Details</h3>} className="mb-4">
                        <div className="form-group pb-3">
                            <label>Project Name</label>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group pb-3">
                            <label>Description</label>
                            <textarea defaultValue={description} onChange={(e) => setDescription(e.target.value)} className="form-control" maxLength={500} />
                        </div>
                        <button className="btn btn-success" onClick={save}>
                            Save Project Details
                        </button>
                    </CardComponent>

                    <CardComponent
                        header={
                            <h3 className="m-0">
                                Prose <sup>(Premium)</sup>
                            </h3>
                        }
                        className="mb-4"
                    >
                        <p>
                            You are an active Manuscriptus <em className="fw-bold">Prose</em> <sup>(Premium)</sup> account holder which makes some additional features available:
                        </p>
                        <ul>
                            <li>A wide array of Icons & Colors for your documents and folders.</li>
                            <li>...</li>
                        </ul>
                    </CardComponent>
                </div>
            </div>
        </div>
    );
}
