import React, { useContext, useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";

import ProjectContext from "system/ProjectContext";

import Icon from "components/Icon";

import FileTreeComponent from "./LeftPane/FileTreeComponent";
import ContextMenuComponent from "./LeftPane/ContextMenuComponent";

export default function LeftPaneComponent(props) {
    const { project, files } = useContext(ProjectContext);
    const [width, setWidth] = useState(parseInt(localStorage.getItem("leftPaneWidth") ?? 250));
    const [context, setContext] = useState(false);

    const leftPane = useRef(null);
    const resize = useRef(null);

    useEffect(() => {
        //console.log('FileTreeComponent useEffect', files);
        const handleContextMenu = (event) => {
            event.preventDefault();
            if (event.srcElement.nodeName === "A") {
                setContext(event);
            } else {
                setContext(false);
            }
        };

        const closeContextMenu = (event) => {
            setContext(false);
        };

        const element = leftPane.current;

        element.addEventListener("contextmenu", handleContextMenu);
        document.addEventListener("click", closeContextMenu);

        const handleDrag = (event) => {
            if (event.clientX > 200) {
                setWidth(event.clientX);
                localStorage.setItem("leftPaneWidth", event.clientX);
            }
        };
        resize.current.addEventListener("drag", handleDrag);

        return () => {
            try {
                //resize.current.removeEventListener("drag", handleDrag);
                //element.removeEventListener("contextmenu", handleContextMenu);
                //document.removeEventListener("click", closeContextMenu);
            } catch (e) {}
        };
    }, []);

    const hideSidebar = () => {
        document.getElementById("left-pane").classList.remove("show");
    };

    return (
        <>
            <div id="left-pane" className={"bg-light "} style={{ width: width }}>
                <div id="left-pane-header">
                    <Link to={"/project/" + project.id + "/settings"} className="btn btn-secondary btn-block m-0 rounded-0" onClick={() => hideSidebar(false)}>
                        <Icon icon="BiCog" /> Project Dashboard
                    </Link>
                </div>
                <div id="left-pane-main">
                    <div id="project-nav-items" ref={leftPane}>
                        {files && <FileTreeComponent {...props} />}
                    </div>
                    <div className="ps-2">
                        <Link to={"/project/" + project.id + "/trash"} className="text-secondary" onClick={() => hideSidebar(false)}>
                            <Icon icon="BiTrash" /> Trash
                        </Link>
                    </div>
                </div>
                <div id="left-pane-bottom">
                    <Link to="/" className="btn btn-primary btn-sm" onClick={() => hideSidebar(false)}>
                        <Icon icon="FaList" color="#fff" /> Projects List
                    </Link>
                    <a href="https://manuscriptus.net/" className="btn btn-outline-primary btn-sm text-black">
                        <Icon icon="FaWindowClose" /> Exit
                    </a>
                </div>
                <div id="left-pane-resize" ref={resize} draggable={true}></div>
            </div>
            {context && <ContextMenuComponent event={context} />}
        </>
    );
}
