import React, { useState, useContext } from "react";

import Platform from "system/Platform";

import ProjectContext from "system/ProjectContext";
import CardComponent from "components/CardComponent";

import Icon, { icons, libs as icon_libraries, icon_colors } from "components/Icon";
import SectionComponent from "components/SectionComponent";

export default function MetaInputComponent(props) {
    const { getFile, setFile } = useContext(ProjectContext);
    const save = ["name", "description"];
    let data = getFile(props.fileId);
    const [file, setLocalFile] = useState(data);
    const [iconExpand, setIconExpand] = useState(file.icon.substr(0, 2));
    const [expand, setExpand] = useState(true);

    const handeChange = (event) => {
        const localFile = {...file};
        const prop = event.target.name;
        const value = event.target.value;
        localFile[prop] = value;
        setLocalFile(localFile);
    };

    const setFileProp = (prop, value) => {
        const newFile = { ...file };
        newFile[prop] = value;
        setLocalFile(newFile);
        setFile(newFile);
        const values = {};
        values[prop] = value;
        updateFileMeta(values);
        return newFile;
    };

    const updateProps = () => {
        const values = {};
        save.forEach((prop) => {
            values[prop] = file[prop];
        });

        updateFileMeta(values);
        setFile(file);
    };

    const updateFileMeta = (values) => {
        return Platform.system.updateFileMeta(props.fileId, values);
    };

    const IconsList = (props) => {
        const icon_options = [];
        for (const name in props.icons) {
            if (typeof props.icons[name] === "function") {
                const a_props = {
                    key: name,
                    title: name,
                    className: "",
                    onClick: (e) => setFileProp("icon", name),
                };
                a_props.className += name.toLowerCase() === file.icon.toLowerCase() ? " active" : "";
                const AIcon = props.icons[name];
                icon_options.push(
                    <span role="button" {...a_props}>
                        <AIcon />
                    </span>
                );
            } else if (typeof props.icons[name] === "string") {
            }
        }
        return icon_options;
    };

    let changed = false;
    save.forEach((prop) => {
        if (file[prop] !== data[prop]) {
            changed = true;
        }
    });

    const expandIconSet = (prefix) => {
        setExpand(false);
        setIconExpand(prefix);
        window.setTimeout(() => {
            setExpand(true);
        }, 10);
    };

    const IconLibraries = () => {
        const list = [];
        for (const prefix in icon_libraries) {
            const Library = icon_libraries[prefix];
            const expanded = prefix === iconExpand;

            if (Library.show) {
                list.push(
                    <div key={prefix}>
                        {expanded && (
                            <div role="button" onClick={() => expandIconSet(false)}>
                                <b>{Library.name}</b> <Icon icon="FaAngleDown" />
                            </div>
                        )}
                        {!expanded && (
                            <div role="button" onClick={() => expandIconSet(prefix)}>
                                <b>{Library.name}</b> <Icon icon="FaAngleUp" />
                            </div>
                        )}
                        {expanded && (
                            <div className="icon-selection">
                                {expand && <IconsList icons={Library.icons} />}
                                {Library.url && (
                                    <div>
                                        Icons thanks to&nbsp;
                                        <a href={Library.url} target="_blank" rel="noreferrer">
                                            {Library.url}
                                        </a>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                );
            }
        }
        return list;
    };

    return (
        <div className="c2 p-4">
            <CardComponent className="cpb mb-4">
                <fieldset>
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input type="text" name="name" value={file.name} placeholder="Name" className="form-control" onChange={(e) => handeChange(e)} maxLength={60} />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Description:</label>
                        <textarea name="description" defaultValue={file.description} onChange={(e) => handeChange(e)} className="form-control" maxLength={500} />
                    </div>

                    <div className="mb-3">
                        {changed && (
                            <button type="button" onClick={() => updateProps()} className="btn btn-primary">
                                Save Changes
                            </button>
                        )}
                        {!changed && (
                            <button type="button" className="btn btn-primary" disabled>
                                Save Changes
                            </button>
                        )}
                    </div>
                </fieldset>
            </CardComponent>

            <CardComponent className="cpb mb-4" header="Icon">
                <fieldset>
                    <div className="icon-selection">
                        <IconsList icons={icons} />
                    </div>
                    <SectionComponent>Manuscriptus Prose Features Below</SectionComponent>
                    <div className="icon-selection">
                        {icon_colors.map((color) => {
                            return (
                                <span onClick={() => setFileProp("icon_color", color)} className={color === file.icon_color ? "active" : ""} role="button" key={color}>
                                    <Icon icon={file.icon} color={color} />
                                </span>
                            );
                        })}
                    </div>
                    <IconLibraries />
                </fieldset>
            </CardComponent>

            <CardComponent className="cpb mb-4">
                {file.template && (
                    <>
                        <p>This {file.type} is available as a template in the project.</p>
                        <button className="btn btn-warning" onClick={() => setFileProp("template", false)}>
                            Remove as Template
                        </button>
                    </>
                )}
                {!file.template && (
                    <>
                        <p>You may add this {file.type} as a template in this project.</p>
                        <button className="btn btn-primary" onClick={() => setFileProp("template", true)}>
                            Add as Template
                        </button>
                    </>
                )}
            </CardComponent>
        </div>
    );
}
