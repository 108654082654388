import React, { useState, useEffect } from "react";

import Platform from "../system/Platform";
import Icon from "components/Icon";
import Loading from "../components/Loading";
import { Link, useNavigate } from "react-router-dom";


export default function LandingScreen(props) {
    const [projects, setProjects] = useState(false);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    document.title = "Manuscriptus";

    const handleSave = () => {
        setLoading(true);
        Platform.system.createProject(name).then((response) => {
            console.log("createProject", response);
            if (response.data) {
                navigate("/project/" + response.data.id + "/view");
            }
        });
    };

    useEffect(() => {
        Platform.system.listProjects().then((response) => {
            setProjects(response.data.projects); // Will need to mutate GoogleDrive
        });
    }, []);

    if (projects) {
        return (
            <div className="container py-4">
                <div className="row">
                    <div className="col-lg-8">
                        <h1>Your Manuscriptus Projects</h1>
                    </div>
                    <div className="col-lg text-end">
                        <a href="https://manuscriptus.net/" className="btn btn-secondary">
                            <Icon icon="FaWindowClose" /> Exit
                        </a>
                    </div>
                </div>

                {projects.length > 0 ? (
                    <p className="small">
                        <em>Click to open a project</em>
                    </p>
                ) : (
                    <p className="small">
                        <em>You have no projects yet</em>
                    </p>
                )}
                <div className="projects">
                    {projects.map((project) => {
                        return (
                            <div key={project.id} className="project row">
                                <div className="col">
                                    <Link to={`/project/${project.id}/view`} key={project.id} className="project-link">
                                        <Icon icon="FaBook" /> {project.name}
                                    </Link>
                                </div>
                                <div className="col text-end">
                                    {project.words} words<br/> 
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="card my-4">
                    <div className="card-header">Create a New Project</div>
                    <div className="card-body">
                        <div className="input-group">
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control form-control-lg" placeholder="Project Name" />
                            <button type="button" onClick={handleSave} disabled={loading} className="btn btn-success">
                                <Icon icon="FaPlus" /> Create Project
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Loading>Loading Manuscriptus</Loading>;
    }
}
