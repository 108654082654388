import Icon from "components/Icon";

import axios from "axios";

import config from "config";

const ManuscriptusAuth = {
    name: "Manuscriptus",
    client: null,
    setCredentials: function (values) {
        console.log("setCredentials", values);
        localStorage.setItem("platform_credential", JSON.stringify(values));
    },
    getCredentials: function () {
        const credentials = localStorage.getItem("platform_credential");
        if (credentials) {
            try {
                return JSON.parse(credentials);
            } catch (e) {}
        }
        return false;
    },
    isAuthenticated: function () {
        return this.getCredentials();
    },

    refreshToken: function (callback) {
        if (!ManuscriptusAuth.client) {
            ManuscriptusAuth.initializeLogin();
        }
        ManuscriptusAuth.client.requestAccessToken({ callback: callback });
    },

    checkStatus: () => {
        return axios
            .get(config.BASE_URL + "api/user", {
                withCredentials: true
            })
            .catch((e) => {
                console.error(e);
            });
    },

    fetchAccessCode: () => {
        return axios
            .get(config.BASE_URL + "tokens/create", {
                withCredentials: true
            })
            .then((response) => {
                console.log();
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    LoginComponent: function () {
        return (
            <form onSubmit={() => ManuscriptusAuth.login()}>
                <button onClick={() => ManuscriptusAuth.login()} className="btn btn-primary btn-lg btn-block">
                    <Icon icon="FaLogin" /> Login
                </button>
            </form>
        );
    },
    login: function () {
        ManuscriptusAuth.client.requestAccessToken();
    },
    logout: function () {
        localStorage.removeItem("platform_credential");
        window.location.reload();
    },
};

export default ManuscriptusAuth;
