import React, { useState } from "react";

import Icon from "components/Icon";

export default function FontSizeComponent(props) {
    const [fontSize, setFontSize] = useState(localStorage.getItem("fontSize") ?? 14);
    const modFontSize = (mod = +1) => {
        const s = parseFloat(fontSize);
        const ns = s + mod;
        if (ns >= 11 && ns <= 21) {
            setFontSize(ns);
            localStorage.setItem("fontSize", ns);
        }
    };

    const style = `{ font-size: ${fontSize}px }`;
    return (
        <span className="btn-group text-light">
            <button className="btn btn-default pe-1 text-light" onClick={() => modFontSize(-0.25)}>
                <Icon icon="FaMinus" />
            </button>
            <span className="btn btn-default px-0 text-light">font size</span>
            <button className="btn btn-default ps-1 text-light" onClick={() => modFontSize(+0.25)}>
                <Icon icon="FaPlus" />
            </button>
            <style>.editor-content {style}</style>
        </span>
    );
}
