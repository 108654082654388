import { Link } from "react-router-dom";

export default function AboutScreen(props) {
    document.title = "About Manuscriptus";

    return (
        <div className="container my-4">
            <Link to="/">Back</Link>
            <h1>About Manuscriptus</h1>
            <p>Manuscriptus is a free, web-based software for writing manuscripts.</p>
        </div>
    );
}
