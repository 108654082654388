
import Manuscriptus from "./Manuscriptus";
import ManuscriptusAuth from "./ManuscriptusAuth";

const Platform = {
    logout: function () {
        this.auth.logout();
        window.location.reload();
    },
    fileById: function(files, fileId){
        for(const idx in files){
            if(files[idx] && files[idx].id === fileId){
                return files[idx];
            }
        }
        return false;
    },
    toTree: function (files, project_id) {
        let tree = [],
            mappedArr = {};
        files.forEach(function (file) {
            if (file.show && !mappedArr.hasOwnProperty(file.id)) {
                mappedArr[file.id] = file;
                mappedArr[file.id].children = [];
            }
        });
        for (let id in mappedArr) {
            if (mappedArr.hasOwnProperty(mappedArr[id].parent_id)) {
                if (mappedArr[id].parent_id
                    && mappedArr[id].parent_id.length > 0
                     && mappedArr[id].parent_id !== project_id) {
                    mappedArr[mappedArr[id].parent_id].children.push(mappedArr[id]);
                } else {
                    // GoogleDrive Top Level
                    tree.push(mappedArr[id]);
                }
            } else {
                //Other Top Level
                if(mappedArr[id].parent_id === ""){
                    tree.push(mappedArr[id]);
                } else {
                    //these are stranded... probably due to their parent folder being deleted
                }
                
            }
        }
        return tree;
    },
    system: Manuscriptus,
    auth: ManuscriptusAuth,
};

export default Platform;
