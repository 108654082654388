import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";

import Platform from "./system/Platform";

import LandingScreen from "./screens/LandingScreen";
import ProjectScreen from "./screens/ProjectScreen";
import LoginScreen from "./screens/LoginScreen";
import AboutScreen from "./screens/AboutScreen";
import Error404Screen from "./screens/Error404Screen";

import AuthContext from "./system/AuthContext";

export default function App(props) {
    const [isloggedIn, setLoggedIn] = useState(false);

    const ProjectScreenWrapper = (props) => {
        const params = useParams();
        return <ProjectScreen {...params} {...props} />;
    };

    const routesList = () => {
        const routes = [<Route exact path="/about" element={<AboutScreen />} key="about" />];
        if (isloggedIn) {
            routes.push(<Route exact path="/" element={<LandingScreen />} key="landing" />);
            routes.push(<Route path="project/:project_id/view" element={<ProjectScreenWrapper mode="project" />} key="project" />);
            routes.push(<Route path="project/:project_id/settings" element={<ProjectScreenWrapper mode="project_settings" />} key="project_settings" />);
            routes.push(<Route path="project/:project_id/trash" element={<ProjectScreenWrapper mode="trash" />} key="trash" />);
            routes.push(<Route path="project/:project_id/file/:fileId" element={<ProjectScreenWrapper mode="file" />} key="file" />);
            routes.push(<Route path="project/:project_id/file/:fileId/:action" element={<ProjectScreenWrapper mode="file_action" />} key="file_action" />);
            routes.push(<Route path="*" element={<Error404Screen />} key="login" />);
        } else {
            routes.push(<Route path="*" element={<LoginScreen />} key="login" />);
        }
        return routes;
    };

    return (
        <AuthContext.Provider value={{ isloggedIn: isloggedIn, setLoggedIn: setLoggedIn }}>
            <Router>
                <Routes>{routesList()}</Routes>
            </Router>
        </AuthContext.Provider>
    );
}
