import React, { useState, useEffect, useContext } from "react";

import { NavLink } from "react-router-dom";

import ProjectContext from "system/ProjectContext";

import { SortableList } from "@thaddeusjiang/react-sortable-list";
import { SortableItemTr } from "../../SortableItemTr.tsx";
import Icon from "components/Icon";

import { utcFormat, numberFormat, truncate } from "functions";

export default function FolderPaneComponent(props) {
    const { files, updateFilesOrder, addFile, addFolder, duplicateFile, templates } = useContext(ProjectContext);
    const [children, setChildren] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (typeof files === "object") {
            const newChildren = files.filter((file) => {
                return file.parent_id === props.fileId;
            });
            setChildren(newChildren);
        }
    }, [files, props.fileId]);

    const add = (type) => {
        const name = prompt("Name");
        if (name) {
            if (type === "file") {
                addFile(name, props.fileId);
            } else {
                addFolder(name, props.fileId);
            }
        }
    };

    const addTemplate = (template) => {
        const name = prompt("Name", "");
        if (name) {
            duplicateFile(template, name, props.fileId);
        }
    };

    const handleChildrenMove = (items) => {
        setChildren(items);
        setIsLoaded(true);
    };

    const updateChildren = () => {
        setIsLoaded(false);
        const updates = {};
        children.forEach((file, idx) => {
            updates[file.id] = idx;
        });
        updateFilesOrder(updates);
    };

    useEffect(() => {
        if (isLoaded) {
            updateChildren();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, isLoaded]);

    const DragHandler = (props) => {
        return (
            <td>
                <Icon icon="FaArrowsAltV" {...props} />
            </td>
        );
    };
    var words_total = 0;
    children.forEach(file => {
        words_total += file.words;
    })
    return (
        <>
            {children.length > 0 && (
                <div className="table-responsive">
                    <table className="table table-striped table-sm FolderItemsGrid">
                        <thead>
                            <tr>
                                <th width={20}></th>
                                <th>Name</th>
                                <th width={90}>Type</th>
                                <th width={90}>Words</th>
                                <th width={220}>Created</th>
                                <th width={220}>Modified</th>
                            </tr>
                        </thead>
                        <tbody>
                            <SortableList items={children} setItems={handleChildrenMove}>
                                {({ items }) => (
                                    <>
                                        {items.map((file) => {
                                            return (
                                                <SortableItemTr key={file.id} id={file.id} DragHandler={DragHandler}>
                                                    <td>
                                                        <NavLink to={file.link}>
                                                            <Icon icon={file.icon} color={file.icon_color} /> {truncate(file.name, 25)}
                                                        </NavLink>
                                                    </td>
                                                    <td>
                                                        <Icon icon={file.type} /> {file.type}
                                                    </td>
                                                    <td>{file.type == "file" ? numberFormat(file.words) : ""}</td>
                                                    <td>{utcFormat(file.created_at)}</td>
                                                    <td>{utcFormat(file.updated_at)}</td>
                                                </SortableItemTr>
                                            );
                                        })}
                                    </>
                                )}
                            </SortableList>
                            <tr className="small">
                                <td className="text-end" colSpan={3}>Total Words:</td>
                                <td>{numberFormat(words_total)}</td>
                                <td colSpan={2}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            {children.length < 1 && <p className="m-3">No Files</p>}
            <div className="mt-3">
                <button onClick={() => add("file")} className="btn btn-success mx-2">
                    <Icon icon="FaFileMedical" /> &nbsp;Add File
                </button>
                <button onClick={() => add("folder")} className="btn btn-success mx-2">
                    <Icon icon="BiFolderPlus" /> &nbsp;Add Folder
                </button>
                {templates().map((template) => {
                    return (
                        <button onClick={() => addTemplate(template)} className="btn btn-success mx-2" key={template.id}>
                            <Icon icon={template.icon} /> &nbsp;Add {template.name}
                        </button>
                    );
                })}
            </div>
        </>
    );
}
